.home {
  display: flex;
  width: 100vw;
  min-height: 70vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-heading,
.top-projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.home-projects-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-text h1 {
  font-size: 5rem;
  margin-bottom: -1.25rem;
}

.home-text h4 {
  margin-top: 10px;
  font-size: 1.38rem;
}
.home-text {
  margin: 0 10px;
}
.home-img {
  margin: 20px 10px;
  width: 200px;
}

.top-projects {
  margin: 0 0 40px;
}

@media screen and (max-width: 900px) {
  .home-heading {
    flex-direction: column;
  }
  .home-text {
    align-items: center;
    text-align: center;
  }
  .home-projects-section h2 {
    font-size: 1.4rem;
  }
  .home-projects-section {
    width: 90%;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .home-heading {
    flex-direction: column;
  }
  .home-text h1 {
    font-size: 3.7rem;
  }
  .home-text h4 {
    font-size: 1.3rem;
  }
}
