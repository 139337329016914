footer {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
}

footer > h4 {
  margin-bottom: 5px;
  color: #fa9057;
}

footer > .social-icons > * {
  margin: 5px 10px;
}

footer > .social-icons > a * {
  transition: all 0.5s ease-out;
}

footer > .social-icons > :hover * {
  color: #fa9057;
}
