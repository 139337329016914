* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: white;
}

body {
  font-family: "Futura", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282838;
}

h1 {
  font-family: "Lust", serif;
}

.dark-blue {
  color: #282838;
}

.orange {
  color: #fa9057;
}
