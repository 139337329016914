.project-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detail-back-button,
.detail-back-button * {
  color: #fa9057;
  font-weight: bold;
  font-size: 2rem;
}

.detail-back-button {
  background: none;
  border: none;

  cursor: pointer;
}
