.resume {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #282838;
}

.back-button,
.back-button * {
  color: #fa9057;
  font-weight: bold;
  font-size: 2rem;
}

.back-button {
  background: none;
  border: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
}
