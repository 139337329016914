.contact,
.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-heading {
  margin: 40px 20px;
  max-width: 800px;
  text-align: center;
}

form *:not(label) {
  color: black;
}

.contact-text {
  margin: 40px 20px;
  max-width: 600px;
  text-align: center;
  background-color: #fa9057;
  padding: 10px;
  border-radius: 7px;
}
.contact-text * {
  color: #282838;
}
.contact-form label {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.contact-form button {
  background-color: #fa9057;
  color: #282838;
  padding: 5px 10px;
  border-radius: 7px;
  border: none;
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-form textarea {
  resize: none;
  font-family: inherit;
}

.contact-form input,
.contact-form textarea {
  font-size: 1.2rem;
  padding: 5px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline-color: #fa9057;
}
