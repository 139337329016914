header {
  background-color: black;
  min-height: 10vh;
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo {
  display: flex;
  align-items: center;
}
.header-logo img {
  width: 100px;
  margin-right: 10px;
}
nav > * {
  margin: 0 10px;
}

@media screen and (max-width: 640px) {
  header {
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
  }
  nav {
    margin-top: 10px;
  }
}
